<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.id" class="text-center">Add New Offers</h4>
                <h4 v-if="payload.id" class="text-center">Update Offer</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Label</b></label>
                      <input
                        type="text"
                        v-model="payload.label"
                        class="form-control form-control-sm"
                        placeholder="Label"
                      />
                      <span class="error" v-if="error && error.label">{{
                        error.label
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Min Coins</b></label>
                      <input
                        type="number"
                        v-model="payload.coins"
                        class="form-control form-control-sm"
                        placeholder="Min Coins"
                      />
                      <span class="error" v-if="error && error.coins">{{
                        error.coins
                      }}</span>
                    </div>
                  </div>
                  <!-- <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Applicable To</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.currency"
                        class="form-control form-control-sm"
                      >
                        <template v-for="(item, idx) in regionsList" :key="idx">
                          <option :value="item.id">{{item.id}}</option>
                        </template>
                      </select>
                    </div>
                  </div> -->
                  <!-- <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Discount Type</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.discount_type"
                        class="form-control form-control-sm"
                      >
                        <option value="FLAT_EXTRA_COINS">FLAT_EXTRA_COINS</option>
                        <option value="EXTRA_COINS_PERCENTAGE">EXTRA_COINS_PERCENTAGE</option>
                        <option value="DISCOUNT_PRICE">DISCOUNT_PRICE</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Percentage</b></label>
                      <input
                        type="number"
                        v-model="payload.value"
                        class="form-control form-control-sm"
                        placeholder="Percentage"
                      />
                      <span class="error" v-if="error && error.value">{{
                        error.value
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Display Order</b></label>
                      <input
                        type="number"
                        v-model="payload.order"
                        class="form-control form-control-sm"
                        placeholder="Order"
                      />
                      <span class="error" v-if="error && error.order">{{
                        error.order
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option :value="true">Active</option>
                        <option :value="false">InActive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.id"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Offer
                    </button>
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Offer
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Offers</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <!-- <div class="col-2">
                    <div class="form-group">
                      <label for=""><b>User Type</b></label>
                      <select name="" id="" v-model="user_type" class="form-control form-control-sm">
                        <option value="all">All</option>
                        <option value="superuser">superuser</option>
                          <option value="trainer">trainer</option>
                          <option value="student">student</option>
                      </select>
                    </div>
                  </div> -->
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add Offer
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>LABEL</th>
                  <th>Min Coins</th>
                  <!-- <th
                    :class="{
                      active_sort: sort_by == 'discount_type' || sort_by == '-discount_type',
                    }"
                    @click="sortBy('discount_type')"
                  >
                    DISCOUNT TYPE
                    <i
                      :class="{
                        'fa-sort': sort_by != 'discount_type',
                        'fa-sort-up': sort_by == 'discount_type',
                        'fa-sort-down': sort_by == '-discount_type',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th> -->
                  <th>Percentage</th>
                  <th>Display ORDER</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.label || '-' }}</td>
                      <td>{{ item.coins }}</td>
                      <!-- <td>{{ item.discount_type }}</td> -->
                      <td>{{ item.value }}%</td>
                      <td>{{ item.order }}</td>
                      <td>
                        <span class="text-success" v-if="item.status"
                          ><b>Active</b></span
                        >
                        <span class="text-warning" v-else><b>Inactive</b></span>
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        />
                      </td>
                    </tr>
                  </template>
                </template>
                <footer-table
                  :data="dataList"
                  :loading="loading_list"
                  :pageSize="pageSize"
                  @action="handleTableAction"
                  cols="6"
                ></footer-table>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "discount",
      defaultPayload: {
        label: null,
        currency: 'USD',
        coins: null,
        discount_type: 'EXTRA_COINS_PERCENTAGE',
        value: null,
        order: null,
        status: true
      },
    };
  },
  mixins: [DataMixin],
  created() {
    this.getRegionsList();
  },
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.label === null ||
        that.payload.label === ""
      ) {
        this.error.label = "label is required";
        proceed = false;
      }
      if (that.payload.coins === null || that.payload.coins === "") {
        this.error.coins = "coins is required";
        proceed = false;
      }
      if (that.payload.discount_type === null || that.payload.discount_type === "") {
        this.error.discount_type = "discount_type is required";
        proceed = false;
      }
      if (that.payload.value === null || that.payload.value === "") {
        this.error.value = "value is required";
        proceed = false;
      }
      if (that.payload.order === null || that.payload.order === "") {
        this.error.order = "Order is required";
        proceed = false;
      }
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>